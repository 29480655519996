@import '../../assets/styles/variables.module.scss';

.container {
    font-size: 1.6rem;
    border-style: solid;
    border-width: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    cursor: pointer;
}

.default {
    border-color: transparent;
    width: 20px;
}

.red {
    border-color: $red-primary;
    color: $red-primary;
    width: 30px;
    height: 30px;
}