@import '../../../assets/styles/variables.module.scss';

.container {
    height: 40px;
    width: 100%;
    display: flex;
    background-color: $background-color-all-pages;
    padding: 0rem 1rem;
    
}


.select {
    background-color: $background-color-all-pages;
    border-style: none;
    outline: none;
    
    & option {
        margin: 0rem 1rem;
    }
}

.container_mobile {
    height: 40px;
    display: flex;
    background-color: rgba(255,255,255, 0.1);
    padding: 0rem 1rem;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.select_mobile {
    background-color: transparent;
    border-style: none;
    outline: none;
    color: white;
    
    & option {
        margin: 0rem 1rem;
        color: $color-foreshop;
        width: 100%;
    }
}
