.container {
    height: 45px;
    display: flex;
    align-items: center;
    gap: 1.5rem;
    padding: 1rem 2rem;
    cursor: pointer;
    text-decoration: none;

    &:hover {
        background-color: rgba(255, 255, 255, 0.205);
        border-radius: 50px;
    }
}

.selected {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    padding: 1rem 2rem;
    cursor: pointer;
    text-decoration: none;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 50px;
    height: 45px;
}

.image {
    display: flex;
    & img {
        width: 18px;
    }
}

.name {

    font-size: 1.6rem;
    color: white;
}