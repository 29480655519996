@import '../../assets/styles/variables.module.scss';
@import '../../assets//styles/mixins.module.scss';

.container {
    @include page-container-default;
    grid-row: 1/3;
    padding: 1rem;
}

.main {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    // margin-bottom: 2rem;
    background-color: white;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    position: relative;
    padding: 1rem 1rem;
}

.main_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    @include gray-bottom-border;
}

.main_top_extra_padding {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    // padding: 1rem 0rem;
    margin: 1rem 0rem;
    @include gray-bottom-border;
}


.main_title {
    @include page-title
}

.items_selected_div{
    font-size: 1.2rem;
    font-weight: 600;
    color: $gray-primary;
    // margin-top: 1rem;
}


.main_search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include gray-bottom-border;
    gap: 1rem;
}

.main_operation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
}


.custom_icon_btn{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1rem;  
    margin: 0rem 1rem;  
    border: 1px solid $red-primary;
    border-radius: 1rem;
    color: $red-primary;
    background-color: $seperator_background;
}

.custom_btn_img_text{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1.5rem;  
    margin: 0rem 1rem;  
    border: 1px solid $blue-primary;
    border-radius: 1rem;
    color: white;
    font-weight: 500;
    font-size: 1.2rem;
    background-color: $blue-primary;
    cursor: pointer;
}




.filter {
    display: flex;
    gap: 1rem;
}

.filter_checkboxes {
    display: flex;
    gap: 1rem;
    border-style: solid;
    border-width: 2px;
    border-color: $blue-primary;
    padding: 0rem 2rem;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.filter_checkboxes_item {
    font-size: 1.4rem;
    font-weight: 500;
    color: $blue-primary;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}


.table_data {
    & tr:hover {
        background-color: #e4e1e1;
    }

    & td {
        padding: 1rem 1rem;
        border-style: solid;
        border-color: rgb(211, 211, 211);
        border-width: 1px;
        min-width: 200px;
        max-width: 300px;
        min-height: 200px;
        max-height: 300px;
        text-align: center;
        overflow-x: auto;
        overflow-y: auto;

    }

    & tr:last-child td div{
        top: -15rem ;
    }

    & tr:nth-last-child(2) td div {
        top: -15rem;
    }

    & tr:nth-last-child(3) td div {
        top: -15rem;
    }

    & tr:first-child td div {
        top: 5rem !important;
    }

    & tr:nth-child(2) td div {
        top: 5rem !important;
    }

    & tr:nth-child(3) td div {
        top: 5rem !important;
    }
}


.main_bottom {
    padding-top: 1rem;
    display: flex;
    justify-content: space-between;
    width: 100%;
}



.action_tab_buttons{
    display: flex;
    justify-content: center;
    align-items: center;
    gap:3rem;
height: auto;
    width: 100%;
}


.input_date{
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    background-color: #f3f2f2;
    color: $color-foreshop;
    @include gray-bottom-border;
    // gap: 1rem;
    padding:1rem;

    border-radius: 0.5rem;
    border-style: solid;
    border-color: rgb(211, 211, 211);
    border-width: 1px;
}

.input_date:focus{
    border-color: rgb(211, 211, 211);

}

.action_button_each{
    cursor: pointer;
}

.action_button_each_inactive{
    cursor: progress;
}

.action_button_each_send_now{
    cursor: pointer;
    width: 12%;
    height: auto;
}

.action_button_each_send_now_sending{
    cursor: progress;
    width: 12%;
    height: auto;
}

.action_button_each_website{
    cursor: pointer;
    padding: 0rem .5rem;
    margin: 0rem 1rem
}

.no_data_found_div{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    font-weight: 600;
    color: $red-primary;
    margin: 1rem;
    padding: 1rem;
}


a:link {
    color: black;;
    text-decoration: none;
  }
  
  a:visited {
    color: black;;
    text-decoration: none;
  }
  
  a:hover {
    color: black;;
    text-decoration: none;
  }





// ######################################################## designs for website control ########################################################
.table_data_website {
    & tr:hover {
        background-color: #e4e1e1;
    }

    & td {
        padding: 1rem 0rem;
        border-style: solid;
        border-color: rgb(211, 211, 211);
        border-width: 1px;
        min-width: 50px;
        max-width: 50px;
        min-height: 50px;
        max-height: 100%;
        text-align: center;
        overflow-x: auto;
        overflow-y: auto;
    }

    & tr:last-child td div{
        top: -15rem ;
    }

    & tr:nth-last-child(2) td div {
        top: -15rem;
    }

    & tr:nth-last-child(3) td div {
        top: -15rem;
    }

    & tr:first-child td div {
        top: 5rem !important;
    }

    & tr:nth-child(2) td div {
        top: 5rem !important;
    }

    & tr:nth-child(3) td div {
        top: 5rem !important;
    }
}

.formAlertDiv{
    color: $red-primary;
  }
  

.creative_image_section{
    width: 100%;
}

.favicon_icon{
    width: 40%;
}




// ######################################################## designs for automation tab ########################################################
.funnel_tier_div{
    height: 100%;
    // background-color: #e4e1e1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}


.automation_card{
    background-color: #f3f2f2;
    border-radius: 1rem;
    padding: 1rem;


    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}