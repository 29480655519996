@import "../../../assets/styles/variables.module.scss";

.main_middle {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  gap: 1rem;
}
.creative_info_div{
  text-align: left;
}

.creative_info_div_items{
  font-size: 1.2rem;
  gap:1rem;
  overflow-x: auto;
  margin-top: 1rem;
}

.middle_item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  width: 100%;
  text-align: left;
}

.middle_item_flex_column{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

.middle_item_flex_row{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: auto;
  background-color: $input_background;
  padding: 1rem 1rem 1rem 1rem;
}

.checkbox_daily{
  padding-left: 1rem;
  margin-left: 1rem;
}


.middle_item_flex_column_select{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding: 0px;
}


.middle_item_label {
  font-size: 1.5rem;
  font-weight: 600;
}

.middle_item_label_type_one {
  font-size: 1.2rem;
  font-weight: 500;
}

.middle_item_label_type_one_automtion {
  font-size: 1.2rem;
  font-weight: 500;
  padding-top: 1rem;
}

.half_of_parent_select_div{
  width: 100%;
  max-width: 20rem;
  max-height: 10rem;
  
  display: block;
  overflow-y: scroll !important;
}

.eachdomaindiv{
  background-color: #d3d3d3;

  margin: 0.3rem;
  padding: 0.5rem;

  display: inline-block;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  border-radius: 0.5rem;
}

.eachdomaindiv:hover{
  background-color: #ffbaba;
  margin: 0.3rem;
  padding: 0.5rem;

  display: inline-block;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  border-radius: 0.5rem;
}

.custom_select{
  width: 50%;
  background-color: aqua;
  padding: 1rem;
}




.middle_item_input {
  display: flex;
  gap: 1rem;
  background-color: $input_background;
  padding: 1.5rem 1rem;
  width: 100%;
  // position: relative;

  & input {
    width: 100%;
    font-size: 1.6rem;
    border-style: none;
    outline: none;
    background-color: $input_background;
    color: $gray-primary;
  }
  & span {
    position: absolute;
    background-color: $input_background;
    font-size: 1.6rem;
    left: 4rem;
    color: $gray-primary;
  }
}

.middle_item_input_select_multiple{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  background-color: $input_background;
  padding: 1.5rem 1rem;
  width: 100%;

  & input {
    width: 100%;
    font-size: 1.6rem;
    border-style: none;
    outline: none;
    background-color: $input_background;
    color: $gray-primary;
  }
  & span {
    position: absolute;
    background-color: $input_background;
    font-size: 1.6rem;
    left: 4rem;
    color: $gray-primary;
  }
}



.middle_item_input_custom_select{
  width: 100%;
  // min-width: 200px;
  float: 1;
}

.remove_padding {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.buttons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4rem;
}

.formAlertDiv{
  color: $red-primary;
}

.cancel {
  font-size: 1.4rem;
  color: $red-primary;
  font-weight: 400;
  cursor: pointer;
}

.customButton {
  width: 157px;
}


.orDiv{
  font-style: bold;
  font-size: 1.5rem;
  font-weight: 600;
  // padding: 1rem;
}
