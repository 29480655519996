@import '../../assets/styles/variables.module.scss';

.container {
    grid-row: 1/3;
    position: relative;
    width: 250px;
}

.container_minimized {
    grid-row: 1/3;
    position: relative;
}

.logo_container {
    background-color: $color-foreshop;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    height: $top-components-height;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: $horizontal-line-color;
}

.logo {
    & img {
        width: 140px;
    }
}

.logo_text{
    font-style: bold;
    font-weight: 700;
    color: white;
    font-size: 2rem;
}

.small_logo {
    cursor: pointer;
    & img {
        height: 40px;
    }
}

.minimize {
    cursor: pointer;
}


.navbar_container {
    height: 100%;
    padding-top: 1.5rem;
    background-color: $color-foreshop;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 2rem;
    padding-right: 2rem;
    gap: 0.5rem;
}

.navbar_container_top{
    height: 80%;
}

.navbar_container_bottom{
    height: 20%;
    padding-bottom: 1.5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.logout {
    cursor: pointer;
    transform: rotateY(180deg);
    text-align: center;

}

.currency {
    position: absolute;
    bottom: 4rem;
    margin-left: 3rem;
    margin-right: 3rem;
    padding: 0.5rem 0.5rem;
    left: 0;
    right: 0;
    text-align: center;
    color:white;
    font-size: 1.4rem;
    border-style: solid;
    border-width: 1px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    transition: all 0.3s;
    cursor: pointer;

    &:hover {
        background-color: white;
        color: $color-foreshop;
    }

    & img {
        transform: translateY(0.25rem);
    }

}

.minimize_mobile {
    align-self: flex-end;

    & img {
        width: 35px;
    }
}

.impersonate {
    position: absolute;
    bottom: 8rem;
    left: 0;
    right: 0;
    margin-left: 2rem;
    margin-right: 2rem;
    font-size: 1.6rem;
    color: white;
    border-style: solid;
    border-width: 1px;
    text-align: center;
    padding: 1rem 2rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 500px){

    .container {
        grid-row: 1/3;
        position: relative;
        width: 100%;
        height: 100vh;
    }

    
    .logo_container {
        display: none;
    }

    .currency {
        bottom: 80px
    }
    
}


.blocked_help {
    color: white;
}

.blocked_help_container{
    text-align: center;
}

.terms_of_service_container {
    position: absolute;
    bottom: 14rem;
    left: 0;
    right: 0;
    margin-left: 2rem;
    margin-right: 2rem;
    font-size: 1.6rem;
    color: white;
    border-style: solid;
    border-width: 1px;
    text-align: center;
    padding: 1rem 2rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.terms_of_service{
    font-size: 1.6rem;
    color: white;
}

.terms_link {
    text-align: center;
    width: 100%;
    text-decoration: none;
    font-size: 1.4em;
    color: black;
}

.terms_link_container {
    text-align: center;
}

