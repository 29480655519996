@import './variables.module.scss';

@mixin gray-bottom-border {
    border-bottom-style: solid;
    border-bottom-width: 1px;
    padding-bottom: 1rem;
    border-bottom-color: $horizontal-line-color;
}

@mixin page-container-default {
    background-color: $background-color-all-pages;
    padding-top: $padding-top-all-pages;
    padding-right: 1rem;
    padding-left: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow-y: scroll;
}

@mixin page-title {
    color: $blue-primary;
    font-weight: 600;
    font-size: 1.5rem;
    width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
    // gap: 1rem;
}

@mixin table-header {
    color: $blue-primary;
    font-weight: 500;
    text-align: center;
}

@mixin table-default {
    padding: 1rem;
    width: 100%;
    font-size: 1.2rem;

    & thead th {
        position: sticky;
        top: 0;
        background-color: $input_background;
        min-width: 150px;
        max-width: 300px;
    }

    & th {
        @include table-header;
    }
}