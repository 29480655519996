$color-foreshop: #1d1422;
$background-color-all-pages: rgb(239, 238, 238);
$red-primary: #AC002D;
$blue-primary: #1d1422;
$green-primary: rgb(47,163,20); // #2fa314
$gray-primary: #878787;
$horizontal-line-color: #EFF1F4;
$input_background: #F1F3F6;
$seperator_background: rgba(220, 220, 220, 0.452);

$color: #4361ee;
$color1: #4cc9f0;
$color2: #f72585;

$padding-top-all-pages: 2rem;
$top-components-height: 73px;

// Tables Sizes
$table-height-default: 520px;
$table-max-height-960: 480px;
$table-max-height-910: 430px;
$table-max-height-860: 380px;
$table-max-height-810: 330px;
$table-max-height-760: 280px;
$table-max-height-710: 230px;
$table-max-height-660: 180px;
$table-max-height-610: 130px;
$table-max-height-560: 80px;