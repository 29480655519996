@import '../../../assets/styles/variables.module.scss';

.responsible {
    text-transform: none;
}

.impersonate {
    background-color: rgb(243, 106, 243);
    color: white;
}

.customer_create {
    background-color: rgb(235, 235, 96);
    
}

.website_status_red {
    background-color: $red-primary;
    color: white;
}

.website_status_green {
    background-color: $green-primary;
    color: white;
}

.onair {
    background-color: skyblue;

}

.onair_delete {
    background-color: #3A1B2F;
    color: white;
}

.transfer {
    background-color: $blue-primary;
    color: white;
}

.transfer_delete {
    background-color: black;
    color: white;
}

.transfer_move {
    background-color: orangered;
    color: white
}