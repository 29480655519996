@import '../../../assets/styles/variables.module.scss';

.container {
    width: 40px;
    height: 42.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
   
}

.red {
    background-color: $red-primary;
    box-shadow: 0px 8px 12px rgba(172, 0, 45, 0.3)

}

.blue {
    background-color: $blue-primary;
    box-shadow: 0px 8px 12px rgba(25, 39, 101, 0.3);
}

.green {
    background-color: $green-primary;
}

.black {
    background-color: black;
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.3)
}